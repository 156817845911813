import { useMutation } from '@apollo/client'
import { Button, Checkbox, Select, Skeleton, Tbody, Td, Text, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'src/context/userContext'
import { UPDATE_LANGUAGE } from 'src/graphql/mutations/UPDATE_LANGUAGE'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { Role } from 'src/types/Role'
import { User } from 'src/types/User'
import { usePermissions } from '../../../../context-admin/usePermissions'
import { useTeams } from '../../../../context-admin/useTeams'
import UserModal from './UserModal'

interface Props {
  user: User
}

const EmployeeRow: React.FC<Props> = ({ user }: Props) => {
  const [loadingChangeLanguage, setLoadingChangeLanguage] = useState(false)
  const { userCompany } = useUser()
  const { EventsImplementation, track } = useMixpanel()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    handleTogglePermissionsDrawer,
    setSelectedCollab,
    addCollabsToList,
    removeCollabsFromList,
    collabsToRemoveAccess,
  } = usePermissions()
  const { teams } = useTeams()
  const [t] = useTranslation()
  const [updateUserLanguage] = useMutation(UPDATE_LANGUAGE)
  const toast = useToast()

  const handleEditPermissions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    track(EventsImplementation.EDIT_PERMISSIONS(user.roles!.join(', ')))
    handleTogglePermissionsDrawer()
    setSelectedCollab(user)
  }

  const getUserRole = () => {
    if (user.roles?.includes(Role.ADMIN) && user.roles.includes(Role.MANAGER)) {
      return `Admin e ${t('admin.common.Manager')}`
    }

    if (user.roles?.includes(Role.ADMIN)) {
      return 'Admin'
    }

    if (user.roles?.includes(Role.MANAGER)) {
      return t('admin.common.Manager')
    }

    return t('admin.common.Colab')
  }

  const handleUpdateLanguage = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLoadingChangeLanguage(true)
    try {
      const newLanguageCode = e.target.value
      let languageId = 0
  
      userCompany?.languages?.forEach((language) => {
        if (language.code === newLanguageCode) languageId = language.id
      })
  
      await updateUserLanguage({ variables: { id: user.id.toString(), languageId: languageId.toString() } })
      toast({
        title: t("theUserLanguageWasChangedSuccessfully", { userName: user.name }),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch {
      console.error('An error occurred while changing the language')
      toast({
        title: t('anErrorOccurredWhenChangingUsersLanguage', { userName: user.name }),
        description: t('pleaseTryAgain'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setLoadingChangeLanguage(false)
    }
  }

  const onClickItem = (e: any) => {
    if (e.target.type === 'select-one') return
    onOpen()
  }

  const handleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target

    if (!checked) {
      removeCollabsFromList(id)

      return
    }

    addCollabsToList(user)
  }

  return (
    <>
      <Tbody _hover={{ bg: '#fafafa' }} cursor='pointer' onClick={onClickItem}>
        <Tr>
          <Td fontFamily='Mulish' fontSize='16px' textAlign='left'>
            <div onClick={(e) => e.stopPropagation()}>
              <Checkbox
                id={user.id.toString()}
                isChecked={collabsToRemoveAccess.some((i) => i.id === user.id)}
                onChange={(e) => handleSelection(e)}
              />
            </div>
          </Td>
          <Td fontFamily='Mulish' fontSize='16px' fontWeight='bold' textAlign='left'>
            <Text color='#767F89'>{user.name}</Text>
          </Td>
          <Td fontFamily='Mulish' fontSize='16px' fontWeight='regular' textAlign='left' color='#767F89'>
            {user.area ? user.area.name : 'N/A'}
          </Td>
          <Td fontFamily='Mulish' fontSize='16px' fontWeight='regular' textAlign='left' color='#767F89'>
            {getUserRole()}
          </Td>
          <Td fontFamily='Mulish' fontSize='16px' fontWeight='regular' textAlign='left' color='#767F89' width='250px'>
            {loadingChangeLanguage ? <Skeleton h='48px' w='250px' borderRadius='16px'/> : (
              <>
                {userCompany?.languages && (
                  <Select width='250px' defaultValue={user.language} onChange={handleUpdateLanguage}>
                    <option value=''>{t('admin.home.employeeSection.language')}</option>
                    {userCompany?.languages?.map((item) => (
                      <option key={`${user.id} - ${item.code}`} value={item.code}>
                        {item.code ? t(`${item.code}`) : ''}
                      </option>
                    ))}
                  </Select>
                )}
              </>
            )}
          </Td>
          <Td textAlign='end'>
            {teams && (
              <Button variant='solidGray' onClick={handleEditPermissions}>
                <Text fontFamily='Poppins' fontWeight='bold' fontSize='12px'>
                  {t('editPermissions')}
                </Text>
              </Button>
            )}
          </Td>
        </Tr>
      </Tbody>

      <UserModal isOpen={isOpen} onClose={onClose} user={user} />
    </>
  )
}

export default EmployeeRow
