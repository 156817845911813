import { Button, Flex, Icon, Modal, ModalContent, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCloseSharp } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useCustomPlaylistQuiz } from 'src/context/CustomPlaylistQuizContext'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import Content from 'src/modules/cms/domain/Content'
import LastPlaylistLearningItem from './LastPlaylistLearningItem'

interface Props {
  content: Content
  moduleName?: string
  moduleId?: string
  onClose: () => void
  isOpen: boolean
}

const RemoveContentModal: React.FC<Props> = ({ content, onClose, isOpen, moduleName, moduleId }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { updateUserQuizData, user, getUser } = useUser()
  const { EventsImplementation, track } = useMixpanel()
  const [isLoading, setIsLoading] = useState(false)
  const { removeContentFromPlaylist, removeModuleFromPlaylist } = useCustomPlaylistQuiz()
  const {
    removeLearningItemFromPlaylist,
    playlist,
    isPlaylistRemoved,
    setIsPlaylistRemoved,
    setShowSuccessfulMessage,
    fetchCustomPlaylist,
  } = useCustomPlaylist()
  const toast = useToast()
  const isLastModuleLearningItem =
    playlist?.moduleList?.items?.find((module) => module.title === moduleName)?.contentList?.items?.length === 1
  const isLastPlaylistLearningItem =
    playlist?.moduleList?.items?.flatMap((module) => module.contentList?.items?.flatMap((item) => item)).length === 1

  useEffect(() => {
    return () => {
      if (isPlaylistRemoved) {
        setIsPlaylistRemoved(false)
        setShowSuccessfulMessage(false)
      }
    }
  }, [isPlaylistRemoved, setIsPlaylistRemoved, setShowSuccessfulMessage])

  const handleRemoveContent = async () => {
    try {
      setIsLoading(true)
      const wasContentRemoved = await removeContentFromPlaylist(playlist?.id!, moduleId!, content.id)
      if (!wasContentRemoved.success) throw new Error()
      track(EventsImplementation.REMOVE_LEARNING_ITEM_CONFIRMATION(content.title, isLastModuleLearningItem))
      await fetchCustomPlaylist(user!)
      removeLearningItemFromPlaylist(content)
      if (isLastModuleLearningItem) {
        const wasDeleted = await removeModuleFromPlaylist(playlist?.id!, moduleId!)
        if (!wasDeleted.success) throw new Error()
      }
      await getUser()
      onClose()
    } catch {
      toast({
        status: 'error',
        title: 'Ocorreu um erro ao remover o conteúdo',
        description: 'O conteúdo não pôde ser removido. Por favor, tente novamente.',
        isClosable: true,
        duration: 7000,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    onClose()

    if (isPlaylistRemoved) {
      updateUserQuizData(false)
      history.push('/home')
    }
  }

  return (
    <>
      <Modal onClose={handleClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside'>
        <ModalOverlay>
          <ModalContent
            borderRadius='30px'
            w='90%'
            maxW='732px'
            textAlign='center'
            padding={{ base: '24px', md: '44px 42px 48px' }}
            alignItems='center'
          >
            <Button
              variant='closeButton'
              bg='transparent'
              _hover={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
              top='17px'
              right='14px'
              onClick={handleClose}
            >
              <Icon w='28px' h='28px' as={IoCloseSharp} color='gray.300' />
            </Button>

            <Flex direction='column' mt='8px'>
              {isLastPlaylistLearningItem ? (
                <LastPlaylistLearningItem
                  content={content}
                  isLastModuleLearningItem={isLastModuleLearningItem}
                  moduleId={moduleId!}
                />
              ) : (
                <>
                  <Text fontFamily='Poppins' fontWeight={600} fontSize='20px' textAlign='initial'>
                    {isLastModuleLearningItem
                      ? t('home.Custom Playlist.This is the last module content on your playlist', { moduleName })
                      : t('home.Custom Playlist.Are you sure you want to remove', { contentName: content.title })}
                  </Text>

                  {isLastModuleLearningItem && (
                    <Text fontWeight={600} fontSize='18px' textAlign='initial' mt='16px' color='bluishGray.500'>
                      {t('home.Custom Playlist.Content will no longer be part of your playlist', {
                        contentName: content.title,
                        moduleName,
                      })}
                    </Text>
                  )}

                  <Flex gap='12px' mt='24px'>
                    <Button
                      variant='startCourse'
                      border='2px solid #E5E5E5'
                      fontSize='14px'
                      onClick={!isLoading ? handleRemoveContent : () => { }}
                      data-testid='remove-content-modal-confirmation-button'
                    >
                      <Text>
                        {isLoading
                          ? t('home.Custom Playlist.removing')
                          : isLastModuleLearningItem
                            ? t('home.Custom Playlist.Yes, I want to remove this skill')
                            : t('home.Custom Playlist.remove content')}
                      </Text>
                    </Button>
                    <Button variant='startCourseDark' fontSize='14px' py='7.5px' onClick={handleClose}>
                      <Text>{t('home.Custom Playlist.no')}</Text>
                    </Button>
                  </Flex>
                </>
              )}
            </Flex>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

export default RemoveContentModal
