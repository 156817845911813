import { exploreDataEs } from 'src/components/Explore/exploreDataEs'
import { exploreDataPt } from 'src/components/Explore/exploreDataPt'
import { getLanguageFromStorage } from 'src/modules/internationalization/helpers/handleStorage'

const useExplore = () => {
  const userHasLanguageSet = getLanguageFromStorage()
  const exploreData = !userHasLanguageSet || userHasLanguageSet === 'pt-BR' ? exploreDataPt : exploreDataEs

  const getAllSideList = () => {
    return exploreData
  }

  const getAll = () => {
    return exploreData
  }

  const getNameBySubcategorySlug = (id: string) => {
    let subcategoryName = ''

    exploreData.forEach((item) => {
      if (item.id === id) subcategoryName = item.name
    })

    return subcategoryName
  }

  return { getAll, getAllSideList, getNameBySubcategorySlug }
}

export default useExplore
